;

const jsTab = () => {
    $('.js-tab').on({
        click: function(e) {
            e.preventDefault();
            let target = $(this).attr('data-tab');
            if(!$(this).hasClass('active')) {
                $(this).parent('.tabs-head').find('.js-tab').removeClass('active');
                $(this).addClass('active');
                $(this).parents('.tabs').find('.js-tab-item').hide();
                $(`.js-tab-item[data-tab="${target}"]`).fadeIn();
            }
        }
    })
}

const bettingCards = () => {
  if($('.slider-betting-cards').length) {
    $('.slider-betting-cards').each(function(index) {
      var swiper = new Swiper(`#slider-betting-cards-${index}`, {
        breakpoints: {
          320: {
            slidesPerView: 3.3,
            spaceBetween: 0,
            direction: "vertical",
          },
          769: {
            slidesPerView: 2,
            spaceBetween: 20,
            direction: "horizontal",
          },
          1200: {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: `#slider-betting-cards-${index} .swiper-button-next`,
          prevEl: `#slider-betting-cards-${index} .swiper-button-prev`,
        },
      });
    });
  }
      
}
const gamePlaySlider = () => {
    var swiper = new Swiper("#gamePlaySliderThumbnail .mySwiper", {
        pagination: {
          el: "#gamePlaySliderThumbnail .swiper-pagination",
          clickable: true,
        },
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 3,
            spaceBetween: 4
          },
          640: {
            slidesPerView: 5,
            spaceBetween: 14,
          }
        }
      });
      var swiper2 = new Swiper("#gamePlaySlider", {
        observer: true,
        observeParents: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        thumbs: {
          swiper: swiper,
        },
      });
}
const metaVerseSlider = () => {
    var swiper = new Swiper("#metaVerseSliderThumbnail .mySwiper", {
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 3,
            spaceBetween: 4
          },
          640: {
            slidesPerView: 5,
            spaceBetween: 14,
          }
        },
        pagination: {
          el: "#metaVerseSliderThumbnail .swiper-pagination",
          clickable: true,
        },
        freeMode: true,
        watchSlidesProgress: true,
      });
      var swiper2 = new Swiper("#metaVerseSlider", {
        observer: true,
        observeParents: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        thumbs: {
          swiper: swiper,
        },
      });
}

const chooseChar = () => {
  $('.js-cyborgs-list-item .item').on({
    click: function() {
      let target = $(this).attr('data-char');
      if(!$(this).hasClass('active')) {
        $(this).parent().find('.item').removeClass('active');
        $(this).addClass('active');
        $('.cyborgs-character-list-item').hide().removeClass('active');
        $('.js-board-information').removeClass('active');
        $('.cybor-information').hide();
        $(`.cyborgs-character-list-item[data-char="${target}"]`).fadeIn().addClass('active');
      }
    }
  })
}

const selectClassList = () => {
  $('.cyborgs-list .class-list .item').on({
    click: function() {
      let target = $(this).attr('data-class-list'),
          dataChar = $(`.cyborgs-list-item[data-class-list="${target}"] .item:nth-child(1)`).attr('data-char');
      if(!$(this).hasClass('active')) {
        $(this).parent().find('.item').removeClass('active');
        $(this).addClass('active');
        $('.cyborgs-list-item').removeClass('active');
        $(`.cyborgs-list-item[data-class-list="${target}"]`).addClass('active');
        $(`.cyborgs-list-item[data-class-list="${target}"] .item:nth-child(1)`).trigger('click');
        $('.cyborgs-character-list-item').hide().removeClass('active');
        $(`.cyborgs-character-list-item[data-char="${dataChar}"]`).show();
        $('.cyborgs-character-list-item .cybor-information').hide();
      }
    }
  })
}

const chooseItem = () => {
  $('.js-items-list-item .item').on({
    click: function() {
      let target = $(this).attr('data-char');
      if(!$(this).hasClass('active')) {
        $(this).parent().find('.item').removeClass('active');
        $(this).addClass('active');
        $('.items-character-list-item').hide();
        $(`.items-character-list-item[data-char="${target}"]`).fadeIn();
      }
    }
  })
}
const selectItemsClassList = () => {
  $('.items-list .class-list .item').on({
    click: function() {
      let target = $(this).attr('data-class-list'),
          dataChar = $(`.items-list-item[data-class-list="${target}"] .item:nth-child(1)`).attr('data-char');
      if(!$(this).hasClass('active')) {
        $(this).parent().find('.item').removeClass('active');
        $(this).addClass('active');
        $('.items-list-item').removeClass('active');
        $(`.items-list-item[data-class-list="${target}"]`).addClass('active');
        $(`.items-list-item[data-class-list="${target}"] .item:nth-child(1)`).trigger('click');
        $('.items-character-list-item').hide().removeClass('active');
        $(`.items-character-list-item[data-char="${dataChar}"]`).show();
        $('.items-character-list-item .items-information').hide();
      }
    }
  })
}

const cyborgInformation = () => {
  $('.js-board-information').on({
    click: function() {
      if(!$(this).hasClass('active')) {
        $(this).addClass('active');
        $('.cyborgs-character-list-item.active .cybor-information').fadeIn();
      } else {
        $(this).removeClass('active');
        $('.cyborgs-character-list-item.active .cybor-information').fadeOut();
        
      }
    }
  })
}
const itemsInformation = () => {
  $('.js-board-information-items').on({
    click: function() {
      if(!$(this).hasClass('active')) {
        $(this).addClass('active');
        $('.items-character-list-item.active .cybor-information').fadeIn();
      } else {
        $(this).removeClass('active');
        $('.items-character-list-item.active .cybor-information').fadeOut();
        
      }
    }
  })
}

const popup = () => {
  $('[data-popup]').magnificPopup({
    type:'inline',
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
  });
}

const hammerMenu = () => {
  $('.js-hammer-menu').on('click', function() {
    $(this).toggleClass('active');
    $('.site-navigation').slideToggle('active');
  })
}

$(document).ready(function() {
  jsTab();
  gamePlaySlider();
  metaVerseSlider();
  chooseChar();
  selectClassList();
  cyborgInformation();
  selectItemsClassList();
  chooseItem();
  bettingCards();
  itemsInformation();
  popup();
  hammerMenu();
})